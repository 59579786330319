import { formatDecimal, formatNumber } from '@/utils';
import React from 'react';
import { cn } from '@/utils';
import SikkaPayoutIcon from 'public/assets/icons/sikka-payout.svg';

/**
 * Represents an offer payout component.
 * @param {Object} props - The props object.
 * @param {number} props.amount - The amount to display.
 * @param {string} props.size - The size of the component.
 * @param {string} props.classes - The classes to apply to the component.
 * @param {number} [props.formatValue=10000] - The value at which formatting should switch to 'K' format.
 * @param {boolean} [props.noFormat] - If true, disables formatting of the amount.
 * @returns {string} - The formatted payout amount.
 */
export default function OfferPayout({
  amount,
  size,
  classes,
  formatValue = 10000,
  noFormat
}) {

  const imageClass =
    size === 'large' ? 'text-xl' : size === 'medium' ? 'text-lg' : 'text-base';

  const textClass =
    size === 'large' ? 'text-lg' : size === 'medium' ? 'text-base' : 'text-sm';

  return (
    <div className={cn('flex items-center gap-1', classes?.root)}>
      <SikkaPayoutIcon className={cn(imageClass,classes?.image)} />
      {typeof amount === 'number' ? (
        <p className={cn(textClass, classes?.text)}>{noFormat ? formatDecimal(amount): formatNumber(amount,formatValue)}</p>
      ) : null}
    </div>
  );
}
